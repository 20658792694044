import React from 'react'



const Notfound = () => {
  return (
    <div>
      <h1>404 Not found</h1>
    </div>
  )
}

export default Notfound
